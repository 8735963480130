import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const clientPaymentApi = createApi({
  reducerPath: 'clientPaymentApi',
  baseQuery,
  endpoints: (builder) => ({
    getClientPayment: builder.query({
      query: ({ site_id, page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `site/v1/${site_id}/client-milestones?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['clientPayment'],
    }),
    createClientPayment: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['clientPayment'],
    }),
    updateClientPayment: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['clientPayment'],
    }),
    getClientPaymentByID: builder.query({
      query: ({ id, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['clientPayment'],
    }),
    deleteClientPayment: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['clientPayment'],
    }),
    submitInvoice: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/submit-invoice`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['clientPayment'],
    }),
    markAsPaid: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/mark-as-paid`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['clientPayment'],
    }),
    sendNotification: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/send-notification`,
        method: 'POST',
        body,
      }),
    }),
    dataClientPayment: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/client-milestones/data`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
      providesTags: ['clientPayment'],
    }),
  }),
});

export const {
  useGetClientPaymentQuery,
  useCreateClientPaymentMutation,
  useUpdateClientPaymentMutation,
  useGetClientPaymentByIDQuery,
  useDeleteClientPaymentMutation,
  useSubmitInvoiceMutation,
  useMarkAsPaidMutation,
  useSendNotificationMutation,
  useDataClientPaymentMutation,
} = clientPaymentApi;
