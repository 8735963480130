// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    tasks: {
      root: `${ROOTS.DASHBOARD}/tasks`,
      manageTask: `${ROOTS.DASHBOARD}/tasks/manage`,
      createTask: `${ROOTS.DASHBOARD}/tasks/create`,
    },
    purchaseOrder: {
      root: `${ROOTS.DASHBOARD}/purchaseorder`,
      newPurchaseOrder: `${ROOTS.DASHBOARD}/purchaseorder/create`,
      editPurchaseOrder: `${ROOTS.DASHBOARD}/purchaseorder/edit`,
      confirmPurchaseOrder: `${ROOTS.DASHBOARD}/purchaseorder/confirm`,
    },
    drawings: {
      root: `${ROOTS.DASHBOARD}/drawings`,
      newDrawing: `${ROOTS.DASHBOARD}/drawings/create`,
      editDrawing: `${ROOTS.DASHBOARD}/drawings/edit`,
    },
    assignments: {
      root: `${ROOTS.DASHBOARD}/assignments`,
    },
    documents: {
      root: `${ROOTS.DASHBOARD}/documents`,
      newDocument: `${ROOTS.DASHBOARD}/documents/create`,
      editDocument: `${ROOTS.DASHBOARD}/documents/edit`,
    },
    clientPayments: {
      root: `${ROOTS.DASHBOARD}/clientpayments`,
    },
    dailyLogs: {
      root: `${ROOTS.DASHBOARD}/dailyLogs`,
      newDailyLog: `${ROOTS.DASHBOARD}/dailyLogs/create`,
      editDailyLog: `${ROOTS.DASHBOARD}/dailyLogs/edit`,
    },
  },
};
