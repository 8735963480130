import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery,
    endpoints: (builder) => ({
        registerPlayerId: builder.mutation({
            query: (body) => ({
                url: `main/v1/notification/subscribe`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            }),
        }),
        getNotification: builder.query({
            query: () => ({
                url: `main/v1/notification`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
        }),
        readNotification: builder.mutation({
            query: (id) => ({
                url: `main/v1/notification/${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['notification'],
            transformResponse: (response) => response,
        }),
        getNotificationCount: builder.query({
            query: () => ({
                url: `main/v1/notification/count`,
                method: 'GET',
            }),
            providesTags: ['notification'],
            transformResponse: (response) => response,

        }),
    }),
});

export const {
    useRegisterPlayerIdMutation,
    useGetNotificationQuery,
    useReadNotificationMutation,
    useGetNotificationCountQuery,
} = notificationApi;
