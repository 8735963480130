import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { Button, Stack, Typography } from '@mui/material';
// utils
import { useState } from 'react';
import { fToNow } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, readNotification }) {

  const [expanded, setExpanded] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const handleToggleExpand = () => {
    if (!expanded && !isRead) {
      readNotification();
      setIsRead(true);
    }
    setExpanded(!expanded);
  };

  const renderAvatar = (
    <ListItemAvatar>
      {notification.avatarUrl ? (
        <Avatar src={notification.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          <Box
            component="img"
            src="/assets/icons/notification/ic_mail.svg"
            sx={{ width: 24, height: 24 }}
          />
        </Stack>
      )}
    </ListItemAvatar>
  );

  const renderBody = (
    <>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="body2" component="div">
            {reader(notification?.notifications?.message ?? '')}
          </Typography>
        }
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(notification?.notifications?.created_at ?? Date.now())}
          </Stack>
        }
      />
      <Button
        onClick={handleToggleExpand}
        sx={{ textTransform: 'none', p: 0, mt: 1 }}
        variant="outlined"
      >
        Hide
      </Button>
    </>
  );

  const renderToggleButton = (
    <Button onClick={handleToggleExpand} sx={{ textTransform: 'none', p: 0 }} variant="outlined">
      View
    </Button>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification?.notifications?.title ?? '')}
      secondary={expanded ? renderBody : renderToggleButton}
    />
  );

  const renderUnReadBadge = (notification?.read_at == null && !isRead) && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );



  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  readNotification: PropTypes.func
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
