import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const taskApi = createApi({
    reducerPath: "taskApi",
    baseQuery,
    endpoints: (builder) => ({
        getTask: builder.query({
            query: ({ page, limit, search, status, siteId, type }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (type) {
                    queryParams.append('type', type);
                }

                const apiUrl = `site/v1/${siteId}/tasks?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        getAllTask: builder.mutation({
            query: ({ page, limit, search, status, siteId, type }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (type) {
                    queryParams.append('type', type);
                }

                const apiUrl = `site/v1/${siteId}/tasks?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        createTask: builder.mutation({
            query: ({ body, site_id }) => ({
                url: `site/v1/${site_id}/tasks/new`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tasks"],
        }),
        updateTask: builder.mutation({
            query: ({ body, site_id }) => ({
                url: `site/v1/${site_id}/tasks/edit`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tasks"],
        }),
        getTaskByID: builder.query({
            query: ({ id, site_id }) => ({
                url: `site/v1/${site_id}/tasks/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        checkOverLapTask: builder.mutation({
            query: ({ body, site_id }) => ({
                url: `site/v1/${site_id}/tasks/check-overlap`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tasks"],
        }),
    }),
});

export const { useGetTaskQuery, useCreateTaskMutation, useUpdateTaskMutation, useGetTaskByIDQuery, useGetAllTaskMutation, useCheckOverLapTaskMutation } = taskApi;
