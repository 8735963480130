import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

export function formatDate(dateObject) {
  if (dateObject) {
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based in JS
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return null;
}

export function getMonthAndYear(dateString) {
  const date = new Date(dateString);

  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return { month, year };
}


export function getMonthName(monthNumber, year) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const monthIndex = monthNumber - 1;

  if (monthIndex < 0 || monthIndex > 11) {
    return "Invalid month number";
  }

  const monthName = monthNames[monthIndex];
  return `${monthName} ${year}`;
}

export function getFirstDayOfMonth(month, year) {
  const date = new Date(year, month - 1, 1);
  return date;
}

export function getTimeFromDate(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function getTimeFromISO(isoString) {
  const date = new Date(isoString);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function getTimeFromISOUpdated(isoString, timezoneOffsetMinutes = 0) {
  const date = new Date(isoString);
  
  // Adjust the date to the specified timezone offset
  const utcMilliseconds = date.getTime() + (date.getTimezoneOffset() * 60000);
  const timezoneMilliseconds = utcMilliseconds + (timezoneOffsetMinutes * 60000);
  const adjustedDate = new Date(timezoneMilliseconds);
  
  const hours = adjustedDate.getHours();
  const minutes = adjustedDate.getMinutes();

  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${period}`;
}