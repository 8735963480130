import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
    apiKey: 'AIzaSyBeFnl8CZff83Izhbxyy21z7hRyQVgmHBI',
    authDomain: 'thushi-construction.firebaseapp.com',
    projectId: 'thushi-construction',
    storageBucket: 'thushi-construction.appspot.com',
    messagingSenderId: '120173924147',
    appId: '1:120173924147:web:bb3ebac024318b14d4b846',
    measurementId: 'G-9DLQGKWHFK'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generatePlayerId = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        try {
            const token = await getToken(messaging, { vapidKey: 'BAW3F7xPQMmXJ7Ivejd0Z2IQ-9qODn1YuUbIcrUVu-l-Fr5xyIRQpn01FOK2o1QuuOscX2503cACW9IGZGliAkw' });
            return token;
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
};

export default app;
