import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const dailyLogsApi = createApi({
  reducerPath: 'dailyLogsApi',
  baseQuery,
  endpoints: (builder) => ({
    getDailyLogs: builder.query({
      query: ({ site_id, page, limit, startDate, endDate }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (startDate) {
          queryParams.append('start_date', startDate);
        }

        if (endDate) {
          queryParams.append('end_date', endDate);
        }

        const apiUrl = `site/v1/${site_id}/daily-logs?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['dailyLogs'],
    }),
    createDailyLogs: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/daily-logs/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dailyLogs'],
    }),
    updateDailyLogs: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/daily-logs/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dailyLogs'],
    }),
    getDailyLogsByID: builder.query({
      query: ({ id, site_id }) => ({
        url: `site/v1/${site_id}/daily-logs/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['dailyLogs'],
    }),
    deleteDailyLogs: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/daily-logs/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dailyLogs'],
    }),
  }),
});

export const {
  useGetDailyLogsQuery,
  useCreateDailyLogsMutation,
  useUpdateDailyLogsMutation,
  useGetDailyLogsByIDQuery,
  useDeleteDailyLogsMutation,
} = dailyLogsApi;
